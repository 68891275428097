import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function SectionPartenaire() {
  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  return (
    <div className="bg-art-beige py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-7xl gap-y-16">
          <h2 className="text-4xl lg:text-6xl font-bold tracking-tight text-art-marron-fonce sm:text-6xl">
            Notre démarche qualité
          </h2>
          <div className="mx-auto mt-20 grid max-w-lg grid-cols-4 items-center gap-x-8 sm:max-w-xl sm:grid-cols-3 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-7 gap-y-12">
            <div className="max-h-12 w-full mb-4 ">
              <StaticImage
                height={90}
                src="../../images/GD.png"
                alt="Gentlemen du déménagement"
              />
            </div>
            <div className="max-h-12 w-full mb-4 ">
              <StaticImage
                height={90}
                src="../../images/ADEME.png"
                alt="ADEME"
              />
            </div>
            <div className="max-h-12 w-full object-contain object-center mb-4 ">
              <StaticImage height={90} src="../../images/iam.svg" alt="IAM" />
            </div>
            <div className="max-h-12 w-full object-contain object-center mb-4 ">
              <StaticImage height={90} src="../../images/emg.png" alt="EMG" />
            </div>
            <div className="max-h-12 w-full object-contain object-center mb-4 ">
              <StaticImage
                height={90}
                src="../../images/ADS_EIC_ISO_9001.png"
                alt="EIC 9001"
              />
            </div>
            <div className="max-h-12 w-full object-contain object-center mb-4 ">
              <StaticImage
                height={90}
                src="../../images/ADS_EIC_ISO_14001.png"
                alt="EIC 14001"
              />
            </div>

            <div className="max-h-12 w-full object-contain object-center mb-4 ">
              <StaticImage height={90} src="../../images/CSD.png" alt="CSD" />
            </div>
          </div>
        </div>

        {/* <div className="grid grid-cols-1 gap-x-8 gap-y-16 lg:grid-cols-2">
          <div className="mx-auto w-full max-w-xl lg:mx-0">
            <h2 className="text-4xl lg:text-6xl font-bold tracking-tight text-art-marron-fonce sm:text-6xl">
              Notre démarche qualité
            </h2>
          </div>
          <div className="mx-auto grid flex justify-center w-full max-w-xl grid-cols-2 items-center gap-y-12 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:pl-8">
            <div className="max-h-12 w-full mb-4 grayscale">
              <StaticImage
                height={90}
                src="../../images/GD.png"
                alt="Gentlemen du déménagement"
              />
            </div>
            <div className="max-h-12 w-full mb-4 grayscale">
              <StaticImage
                height={90}
                src="../../images/ADEME.png"
                alt="ADEME"
              />
            </div>
            <div className="max-h-12 w-full object-contain object-center mb-4 grayscale">
              <StaticImage height={90} src="../../images/iam.svg" alt="IAM" />
            </div>
            <div className="max-h-12 w-full object-contain object-center mb-4 grayscale">
              <StaticImage height={90} src="../../images/emg.png" alt="EMG" />
            </div>
            <div className="max-h-12 w-full object-contain object-center mb-4 grayscale">
              <StaticImage
                height={90}
                src="../../images/ADS_EIC_ISO_9001.png"
                alt="EIC 9001"
              />
            </div>
            <div className="max-h-12 w-full object-contain object-center mb-4 grayscale">
              <StaticImage
                height={90}
                src="../../images/ADS_EIC_ISO_14001.png"
                alt="EIC 14001"
              />
            </div>
          </div>
        </div> */}
        <hr />
        <div className="w-full pt-20">
          <h2 className="text-4xl lg:text-6xl font-bold tracking-tight text-art-marron-fonce sm:text-6xl">
            Ils nous font confiance
          </h2>
          <Slider
            {...settings}
            className="h-40 mx-auto grid grid-cols-4 align-middle w-full mt-20  items-center justify-items-center px-4"
          >
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/lyon-logo.png"
                alt="Lyon logo"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/campushep.png"
                alt="Campus HEP"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/groupama.png"
                alt="Groupama"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/APRR.png"
                alt="APRR"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/avenir.png"
                alt="Avenir"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/IDRAC.png"
                alt="IDRAC"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/opac.png"
                alt="opac"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/familiance.png"
                alt="Familiance"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/gan.png"
                alt="gan"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/bosle.png"
                alt="Bosle"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/dorelan.png"
                alt="dorelan"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/sacvl.png"
                alt="sacvl"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/tardy.png"
                alt="tardy"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/odyneo.png"
                alt="odyneo"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/g2s.png"
                alt="G2S"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/carreor.png"
                alt="carreor"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/updesk.png"
                alt="updesk"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/couleurinnovation.png"
                alt="couleurinnovation"
              />
            </div>
            <div className="p-4 flex justify-center items-center">
              <StaticImage
                height={120}
                src="../../images/logos/boitenoire.png"
                alt="boitenoire"
              />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
}
